import { usePopup } from '@/compositions/popup';
import { schemasService } from '@/modules/common/api';
import { genMenuItemEdit, genMenuItemDelete } from '@/utils/list-generators';
import { genMenuItemDefault } from '@/utils/list-generators/menu';
import { prepareObject } from '@/provider/utils';
import { monitoringItemService } from '@/modules/object-types/api';

export function useMonitoringItemMenu() {
  const popup = usePopup();

  return {
    genMenu: monitoringItem => {
      const { id, typeId } = monitoringItem;
      const items = [
        genMenuItemEdit(() => {
          popup.open({
            component: () =>
              import(
                '@/modules/object-types/ui/type-card/general/MonitoringItemEdit.vue'
              ),
            props: {
              monitoringItemId: id,
              parentSchemaId: typeId
            }
          });
        }),
        genMenuItemDefault(
          {
            id: 'alarms',
            icon: '$alert',
            title: 'Default Alarms'
          },
          {
            click: () => {
              popup.open({
                component: () =>
                  import('./MonitoringItemAlarmsDefaultEdit.vue'),
                props: {
                  monitoringItemId: id
                }
              });
            }
          }
        ),
        genMenuItemDefault(
          {
            id: 'apply_default_alarms',
            icon: '$update',
            title: 'Apply default alarms'
          },
          () => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                message: `The settings saved in the objects themselves will be lost. This change is irreversible.\n
                Apply default alarm settings to all existing objects? 
                `,
                onConfirm: async () => {
                  const monitoringItem = prepareObject(
                    await monitoringItemService.fetch(id)
                  );
                  await schemasService.updateValues(
                    [
                      {
                        id: monitoringItem.stateAlarms.id,
                        value: monitoringItem.stateAlarms.value
                      },
                      {
                        id: monitoringItem.stateTags.id,
                        value: monitoringItem.stateTags.value
                      },
                      {
                        id: monitoringItem.stateRepeat.id,
                        value: monitoringItem.stateRepeat.value
                      }
                    ],
                    id,
                    true
                  );
                }
              }
            });
          }
        ),
        genMenuItemDelete(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Delete monitoring item?',
              onConfirm: () => schemasService.delete(id)
            }
          });
        })
      ];
      return items;
    }
  };
}
